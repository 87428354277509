.ticks-table {
    font-size: 10px;
}

.ticks-table th, .order-book-table td {
    padding: 3px !important;
}
  
.ticks-table a {
    margin: 0px !important;
}
  