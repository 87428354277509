.order-book-table {
    font-size: 14px;
}

.order-book-table th, .order-book-table td {
  padding: 2px !important;
}

.order-book-table a {
  margin: 0px !important;
}
