.login-form {
    position:fixed;
    top: 100px;
    right: 20px;
    left: 20px;
}

.form-control {
    max-width: 500px;
    margin: 0 auto;
  }