.register-form {
    position:fixed;
    top: 100px;
    right: 20px;
    left: 20px;
    margin: 0 auto;
    padding: 20px;
    border-radius: 10px;
}

.required-field {
    color: red;
    margin-left: 4px;
  }
